"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _rawMaterial = require("@/api/rawMaterial");

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      rawMaterials: [],
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        keyword: null
      },
      first: {
        uuid: null
      },
      total: 0
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      // this.listQuery.uuid = this.$route.params.rawMaterialId
      const filter = {
        limit: 9999,
        rawMaterialId: this.$route.params.rawMaterialId
      };
      this.listLoading = true;
      (0, _rawMaterial.getRawMaterialScheduledPrices)(filter).then(response => {
        this.list = response.data;

        if (response.first && response.first.uuid) {
          this.first = response.first;
        }

        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    addScheduledPrice() {
      this.$router.push(`/raw-materials/${this.$route.params.rawMaterialId}/scheduled-price/add`);
    },

    back() {
      this.$router.push(`/raw-materials`);
    },

    deleteRawMaterialScheduledPrice(id) {
      return _elementUi.MessageBox.confirm(_i18n.default.t('RawMaterialScheduledPrice.ConfirmDeleteMessage'), _i18n.default.t('RawMaterialScheduledPrice.Confirm'), {
        confirmButtonText: _i18n.default.t('RawMaterialScheduledPrice.Delete'),
        cancelButtonText: _i18n.default.t('RawMaterialScheduledPrice.Cancel'),
        type: 'warning'
      }).then(() => {
        return (0, _rawMaterial.deleteRawMaterialScheduledPrice)(id).then(() => {
          (0, _elementUi.Message)({
            message: _i18n.default.t('RawMaterialScheduledPrice.Recorddeleted'),
            type: 'success',
            duration: 5 * 1000
          });
          this.fetchData();
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.UpdateFailed'),
            type: 'error',
            duration: 5 * 1000
          }); // console.log(err)
        });
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;