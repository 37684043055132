var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("label", [_vm._v("From Date: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          placeholder: _vm.$t("PurchaseRequests.PickDate"),
          type: "datetime",
          clearable: false,
        },
        model: {
          value: _vm.listQuery.fromTime,
          callback: function ($$v) {
            _vm.$set(_vm.listQuery, "fromTime", $$v)
          },
          expression: "listQuery.fromTime",
        },
      }),
      _vm._v(" "),
      _c("label", [_vm._v("To Date: ")]),
      _vm._v(" "),
      _c("el-date-picker", {
        attrs: {
          placeholder: _vm.$t("PurchaseRequests.PickDate"),
          type: "datetime",
          clearable: false,
        },
        model: {
          value: _vm.listQuery.toTime,
          callback: function ($$v) {
            _vm.$set(_vm.listQuery, "toTime", $$v)
          },
          expression: "listQuery.toTime",
        },
      }),
      _vm._v(" "),
      _c("label", [_vm._v("Group By: ")]),
      _vm._v(" "),
      _c(
        "el-select",
        {
          model: {
            value: _vm.listQuery.groupBy,
            callback: function ($$v) {
              _vm.$set(_vm.listQuery, "groupBy", $$v)
            },
            expression: "listQuery.groupBy",
          },
        },
        _vm._l(_vm.groupByOptions, function (option) {
          return _c("el-option", {
            key: option.name,
            attrs: { label: option.name, value: option.key },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
        [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "summary-method": _vm.getSummaries,
            border: "",
            "element-loading-text": "Loading",
            fit: "",
            "highlight-current-row": "",
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.time) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.store ? scope.row.store.name : null) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Target Sale" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.estimatedSale
                            ? "$" + scope.row.estimatedSale.toFixed(0)
                            : null
                        ) +
                        "\n        "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Actual Sale" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.actualSale
                            ? "$" + scope.row.actualSale.toFixed(0)
                            : null
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            {
              attrs: { label: "Limit Cost (30%)" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            scope.row.actualSale
                              ? "$" +
                                  (
                                    scope.row.actualSale.toFixed(0) * 0.3
                                  ).toFixed(2)
                              : null
                          ) +
                          "\n      "
                      ),
                    ]
                  },
                },
              ]),
            },
            [_vm._v("git status\n\n    ")]
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Labour Cost" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.dutyCost
                            ? "$" + scope.row.dutyCost.toFixed(0)
                            : null
                        ) +
                        "\n        " +
                        _vm._s(
                          scope.row.labourCostPercentage
                            ? " (" +
                                (scope.row.labourCostPercentage * 100).toFixed(
                                  2
                                ) +
                                "%)"
                            : null
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Labour Man Hour" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.dutyManHr
                            ? parseFloat(
                                scope.row.dutyManHr.toString()
                              ).toFixed(0)
                            : null
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }