"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _rawMaterial = require("@/api/rawMaterial");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      rawMaterialSchedulePriceChange: {
        id: null,
        rawMaterialId: null,
        rawMaterialName: null,
        startDate: null,
        price: null
      },
      rules: {
        rawMaterialId: {
          required: true,
          message: 'Raw material is required'
        },
        startDate: {
          required: true,
          message: 'Start Date is required'
        },
        price: {
          required: true,
          message: 'Price is required'
        }
      },
      rawMaterials: [],
      buttonText: _i18n.default.t('RawMaterialScheduledPrice.Add')
    };
  },

  async created() {
    const filter = {
      limit: 9999
    };

    if (this.$route.params.rawMaterialId) {
      this.rawMaterialSchedulePriceChange.rawMaterialId = this.$route.params.rawMaterialId;
      filter.uuid = this.$route.params.rawMaterialId;
    }

    this.buttonText = _i18n.default.t('RawMaterialScheduledPrice.Add');
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        this.loading = true;
        (0, _rawMaterial.createRawMaterialScheduledPrice)(this.rawMaterialSchedulePriceChange).then(() => {
          (0, _elementUi.Message)({
            message: 'Raw Material scheduled price change created.',
            type: 'success',
            duration: 5 * 1000
          });
          this.$router.push(`/raw-materials/${this.$route.params.rawMaterialId}/scheduled-price`);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          this.loading = false;
        });
      });
    }

  }
};
exports.default = _default;