"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _rawMaterial = require("@/api/rawMaterial");

var _supplier = require("@/api/supplier");

var _warehouse = require("@/api/warehouse");

var _store = require("@/api/store");

var _category = require("@/api/category");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _priceDialog = _interopRequireDefault(require("./price-dialog"));

var _lodash = _interopRequireDefault(require("lodash.debounce"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PriceDialog: _priceDialog.default
  },

  data() {
    return {
      loading: false,
      rawMaterial: {
        id: null,
        sku: null,
        unit: null,
        nameEng: null,
        nameChin: null,
        supplier: null,
        stores: [],
        warehouses: [],
        categories: [],
        active: 'true',
        supplierId: null,
        subUnitQty: 1,
        subUnit: null
      },
      rules: {
        sku: {
          required: true,
          message: 'SKU is required'
        },
        unit: {
          required: true,
          message: 'Unit is required'
        },
        // supplierId: { required: true, message: 'Supplier is required' },
        nameEng: {
          required: true,
          message: 'Name is required'
        }
      },
      allStoreChecked: false,
      allWarehouseChecked: false,
      stores: [],
      supplierName: null,
      supplierSelectedName: null,
      warehouses: [],
      categories: [],
      dialogPriceFormVisible: false,
      keepAliveDialogPriceFormVisible: false,
      buttonText: 'Add'
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },
  watch: {
    allStoreChecked() {
      if (this.allStoreChecked) {
        this.rawMaterial.stores = [];
        this.stores.forEach(store => {
          this.rawMaterial.stores.push(store.id);
        });
      } else {
        this.rawMaterial.stores = [];
      }
    },

    allWarehouseChecked() {
      if (this.allWarehouseChecked) {
        this.rawMaterial.warehouses = [];
        this.warehouses.forEach(warehouse => {
          this.rawMaterial.warehouses.push(warehouse.id);
        });
      } else {
        this.rawMaterial.warehouses = [];
      }
    },

    dialogPriceFormVisible(val) {
      if (val && !this.keepAliveDialogPriceFormVisible) {
        this.keepAliveDialogPriceFormVisible = true;
      }
    }

  },

  async created() {
    (0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
    (0, _category.getCategories)({
      perPage: 9999
    }).then(categories => {
      this.categories = categories.data;
    });
    /* getSuppliers({ perPage: 9999 }).then(suppliers => {
      this.suppliers = suppliers.data
      this.suppliers.unshift({ name: '', uuid: null })
    }) */

    const warehouses = await (0, _warehouse.getWarehouses)({
      limit: 100
    });
    this.warehouses = warehouses.data;

    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.fetchData();
    } else {
      const mainStore = this.warehouses.find(warehouse => warehouse.name === 'main');
      this.rawMaterial.warehouses.push(mainStore.id);
      this.buttonText = 'Add';
      this.allStoreChecked = true;
    }
  },

  methods: {
    async fetchData() {
      this.loading = true;
      (0, _rawMaterial.getRawMaterial)(this.$route.params.id).then(response => {
        this.rawMaterial = { ...response.data,
          stores: response.data.stores.map(store => store.id),
          warehouses: response.data.warehouses.map(warehouse => warehouse.id),
          categories: response.data.categories.map(category => category.id),
          supplierId: response.data.supplier.uuid
        };
        this.supplierName = this.rawMaterial.supplier.name;
        this.supplierSelectedName = this.rawMaterial.supplier.name;
      }).finally(() => {
        this.loading = false;
      });
    },

    async fetchSupplier(queryString, cb) {
      let keyword = queryString;

      if (queryString === this.supplierSelectedName) {
        keyword = null;
      }

      (0, _lodash.default)(search => {
        (0, _supplier.getSuppliers)({
          perPage: 120,
          search
        }).then(response => cb(response.data.map(supplier => ({
          label: supplier.name,
          value: supplier.uuid
        }))));
      }, 630)(keyword);
    },

    clearSupplier() {
      this.rawMaterial.supplierId = null; // this.listQuery.suppliers = null

      this.supplierSelectedName = null;
    },

    checkSupplier() {
      if (this.supplierSelectedName !== this.supplierName) {
        this.supplierName = this.supplierSelectedName;
      }
    },

    handleSelectSupplier(item) {
      this.rawMaterial.supplierId = item.value; // this.listQuery.suppliers = item.value

      this.supplierSelectedName = item.label;
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        new Promise((success, fail) => {
          if (!this.rawMaterial.supplierId) {
            _elementUi.MessageBox.confirm("Raw Material without Supplier wont be able to add purchase request, It's only for stock take warehousing, the status will be suspended for further purchase", 'Confirm', {
              confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
              cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
              type: 'warning'
            }).then(() => {
              success('ok');
            }).catch(() => {
              fail('ok');
            });
          } else {
            success('ok');
          }
        }).then(() => {
          this.loading = true;

          if (this.$route.params.id) {
            (0, _rawMaterial.editRawMaterial)(this.rawMaterial).then(() => {
              (0, _elementUi.Message)({
                message: 'Raw Material updated.',
                type: 'success',
                duration: 5 * 1000
              });

              if (this.$workbox && this.$workbox.active instanceof Promise) {
                const uniqueValue = window['Symbol'] ? Symbol('unique') : /unique/;
                return Promise.race([this.$workbox.active, Promise.resolve(uniqueValue)]).then(value => {
                  if (value !== uniqueValue) {
                    (0, _rawMaterial.getRawMaterial)(this.$route.params.id).then(() => {
                      this.$router.push(`/raw-materials`);
                    });
                  } else {
                    this.$router.push(`/raw-materials`);
                  }
                }, () => {
                  this.$router.push(`/raw-materials`);
                });
              } else {
                this.$router.push(`/raw-materials`);
              }
            }).catch(err => {
              (0, _elementUi.Message)({
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              }); // console.log(err)

              this.loading = false;
            });
          } else {
            (0, _rawMaterial.createRawMaterial)(this.rawMaterial).then(() => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: 'Raw Material created.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/raw-materials`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              this.loading = false;
            });
          }
        }).catch(() => {
          /* cancel */
        });
      });
    },

    getUnitPrice(rawMaterial) {
      const today = new Date();
      let price = rawMaterial.defaultPrice;
      let sPrice = '';

      if (rawMaterial.scheduledPrices) {
        rawMaterial.scheduledPrices.forEach(scheduledPrice => {
          const startDate = new Date(scheduledPrice.startDate);

          if (today > startDate && sPrice === '') {
            price = scheduledPrice.price;
            sPrice = scheduledPrice.price;
          }
        });
      }

      return price;
    },

    selectingSupplier(selected) {
      // console.log({ selected })
      this.rawMaterial.supplierId = selected;
    },

    onPriceChange(ModelPrice) {
      if (ModelPrice.price === this.rawMaterial.price) {
        this.fetchData(true);
      }
    },

    querySearch(queryString, cb) {
      if (queryString) {
        (0, _rawMaterial.getRawMaterials)({
          keyword: queryString
        }).then(response => {
          if (this.$route.params.id) {
            response.data = response.data.filter(data => data.uuid !== this.$route.params.id);
          }

          cb(response.data);
        });
      } else {
        cb([]);
      }
    },

    handleSelect(item) {
      this.$router.push(`/raw-materials/edit/${item.uuid}`);
    }

  }
};
exports.default = _default;