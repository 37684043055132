"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuex = require("vuex");

var _i18n = _interopRequireDefault(require("@/i18n"));

var _report = require("@/api/report");

var _store = require("@/api/store");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
var _default = {
  /* components: {
      Pagination
    }, */
  data() {
    return {
      uuid: this.$route.params.id,
      list: null,
      listLoading: false,
      listQuery: {
        fromTime: null,
        toTime: null,
        // groupBy: 'week',
        page: 1,
        limit: 9999,
        store_id: '',
        firstWeek: {
          fromTime: '',
          toTime: ''
        },
        secondWeek: {
          fromTime: '',
          toTime: ''
        }
      },
      groupByOptions: [{
        key: 'daily_session',
        name: 'Session'
      }, {
        key: 'hour',
        name: 'Hour'
      }, {
        key: 'date',
        name: 'Day'
      }, {
        key: 'week',
        name: 'Week'
      }, {
        key: 'month',
        name: 'Month'
      }, {
        key: 'year',
        name: 'Year'
      }],
      stores: [],
      total: 0
    };
  },

  computed: { ...(0, _vuex.mapGetters)([// 'name',
    'user']),
    firstWeek: {
      get() {
        return this.listQuery.firstWeek.fromTime; // weekly
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek === null) {
          this.listQuery.firstWeek.fromTime = '';
          this.listQuery.firstWeek.toTime = '';
        } else {
          const d = new Date(secondDateOfWeek);
          const day = d.getDay();
          let diff = d.getDate() - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

          this.listQuery.firstWeek.fromTime = this.formatDate(d.setDate(diff));
          diff = d.getDate() + 7 - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

          this.listQuery.firstWeek.toTime = this.formatDate(d.setDate(diff));
          const FromTime = new Date(this.listQuery.firstWeek.fromTime);
          const ToTime = new Date(this.listQuery.firstWeek.toTime);
          const newFromTime = new Date(FromTime.setDate(FromTime.getDate() + 7));
          const newToTime = new Date(ToTime.setDate(ToTime.getDate() + 7));
          this.listQuery.secondWeek.fromTime = this.formatDate(newFromTime);
          this.listQuery.secondWeek.toTime = this.formatDate(newToTime);
        } // this.fetchData()

      }

    },
    secondWeek: {
      get() {
        return this.listQuery.secondWeek.fromTime; // weekly
      },

      set(secondDateOfWeek) {
        if (secondDateOfWeek === null) {
          this.listQuery.secondWeek.fromTime = '';
          this.listQuery.secondWeek.toTime = '';
        } else {
          const d = new Date(secondDateOfWeek);
          const day = d.getDay();
          let diff = d.getDate() - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

          this.listQuery.secondWeek.fromTime = this.formatDate(d.setDate(diff));
          diff = d.getDate() + 7 - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

          this.listQuery.secondWeek.toTime = this.formatDate(d.setDate(diff));

          if (this.listQuery.firstWeek.fromTime === this.listQuery.secondWeek.fromTime && this.listQuery.firstWeek.toTime === this.listQuery.secondWeek.toTime) {
            const FromTime = new Date(this.listQuery.firstWeek.fromTime);
            const ToTime = new Date(this.listQuery.firstWeek.toTime);
            const newFromTime = new Date(FromTime.setDate(FromTime.getDate() - 7));
            const newToTime = new Date(ToTime.setDate(ToTime.getDate() - 7));
            this.listQuery.firstWeek.fromTime = this.formatDate(newFromTime);
            this.listQuery.firstWeek.toTime = this.formatDate(newToTime);
          }
        } // this.fetchData()

      }

    }
  },

  async created() {
    // const today = new Date()
    (0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
    const d = new Date();
    const day = d.getDay();
    let diff = d.getDate() - 7 - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

    this.listQuery.firstWeek.fromTime = this.formatDate(d.setDate(diff));
    diff = d.getDate() + 6;
    this.listQuery.firstWeek.toTime = this.formatDate(d.setDate(diff));
    const d2 = new Date();
    diff = d2.getDate() - day; // + (day === 0 ? -6 : 1) // adjust when day is sunday

    this.listQuery.secondWeek.fromTime = this.formatDate(d2.setDate(diff));
    diff = d2.getDate() + 6;
    this.listQuery.secondWeek.toTime = this.formatDate(d2.setDate(diff));
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const param = {};
      let current_datetime = new Date();

      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }

        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = this.listQuery[key];
          param[key] = current_datetime.getFullYear() + '-' + (current_datetime.getMonth() + 1) + '-' + current_datetime.getDate() + ' ' + current_datetime.getHours() + ':' + current_datetime.getMinutes() + ':' + current_datetime.getSeconds();
        } else {
          param[key] = this.listQuery[key];
        }
      }

      if (this.listQuery.firstWeek.fromTime !== '') {
        this.listLoading = true;
        (0, _report.getSaleVSLavourCost)({ ...param
        }).then(response => {
          this.list = response.data;
          this.list.forEach((item, index) => {
            if (index > 0 && this.list[index - 1].labourCostPercentage && this.list[index].labourCostPercentage && this.list[index - 1].store.id === this.list[index].store.id) {
              this.list[index]['week_comparison_labour_cost'] = ((this.list[index].labourCostPercentage - this.list[index - 1].labourCostPercentage) * 100).toFixed(2);
              this.list[index]['week_comparison_labour_cost_status'] = parseFloat(this.list[index]['week_comparison_labour_cost']) > 0 ? 'red' : parseFloat(this.list[index]['week_comparison_labour_cost']) < 0 ? 'green' : '';
            } else {
              this.list[index]['week_comparison_labour_cost'] = '';
              this.list[index]['week_comparison_labour_cost_status'] = '';
            }

            if (index > 0 && this.list[index - 1].actualSale && this.list[index - 1].dutyManHr && this.list[index].actualSale && this.list[index].dutyManHr && this.list[index - 1].store.id === this.list[index].store.id) {
              this.list[index]['week_comparison_productivity'] = (this.list[index].actualSale / this.list[index].dutyManHr - this.list[index - 1].actualSale / this.list[index - 1].dutyManHr).toFixed(2);
              this.list[index]['week_comparison_productivity_status'] = parseFloat(this.list[index]['week_comparison_productivity']) > 0 ? 'green' : parseFloat(this.list[index]['week_comparison_productivity']) < 0 ? 'red' : '';
            } else {
              this.list[index]['week_comparison_productivity'] = '';
              this.list[index]['week_comparison_productivity_status'] = '';
            }
          });
          this.total = response.data ? response.data.length : 0;
        }).finally(() => {
          this.listLoading = false;
        });
      } else {
        this.list = [];
        this.total = 0;
      }
    },

    getSummaries() {
      const summary = {};
      let totalActualSale = 0;
      let totalEstimatedSale = 0;
      let totalDutyCost = 0;
      let totalDutyManHr = 0;
      let actualSaleCount = 0;
      let estimatedSaleCount = 0;
      let dutyCostCount = 0;
      let dutyManHrCount = 0;

      if (this.list) {
        this.list.forEach(row => {
          if (row.actualSale) {
            totalActualSale += row.actualSale;
            actualSaleCount++;
          }

          if (row.estimatedSale) {
            totalEstimatedSale += row.estimatedSale;
            estimatedSaleCount++;
          }

          if (row.dutyCost) {
            totalDutyCost += row.dutyCost;
            dutyCostCount++;
          }

          if (row.dutyManHr) {
            totalDutyManHr += row.dutyManHr ? parseFloat(row.dutyManHr.toString()) : 0;
            dutyManHrCount++;
          }
        });
      }

      summary[2] = _i18n.default.t('Order.Total') + ': $' + totalActualSale.toFixed(0) + ' | Avg: $' + (actualSaleCount ? totalActualSale / actualSaleCount : 0).toFixed(0);
      summary[3] = _i18n.default.t('Order.Total') + ': $' + totalEstimatedSale.toFixed(0) + ' | Avg: $' + (estimatedSaleCount ? totalEstimatedSale / estimatedSaleCount : 0).toFixed(0); // summary[4] = 'Total: $' + totalEstimatedSale.toFixed(0) + ' | Avg: $' + (estimatedSaleCount ? totalEstimatedSale / estimatedSaleCount : 0).toFixed(0)

      summary[5] = _i18n.default.t('Order.Total') + ': $' + totalDutyCost.toFixed(0) + ' | Avg: $' + (dutyCostCount ? totalDutyCost / dutyCostCount : 0).toFixed(0);
      summary[6] = _i18n.default.t('Order.Total') + ': ' + (totalDutyManHr ? totalDutyManHr.toFixed(0) : 0) + 'hr' + ' | Avg: ' + (dutyManHrCount ? totalDutyManHr / dutyManHrCount : 0).toFixed(0) + 'hr';
      return summary;
    },

    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();

      if (month.length < 2) {
        month = '0' + month;
      }

      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    }

  }
};
exports.default = _default;