var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/raw-materials" },
                },
                [_vm._v(" < " + _vm._s(_vm.$t("Warehouse.Back")))]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { margin: "10px 0 10px 10px" } }, [
                _vm._v(
                  _vm._s(_vm.rawMaterial ? "SKU: " + _vm.rawMaterial.sku : "")
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "summary-method": _vm.getSummaries,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("WarehouseAreaRawMaterial.Warehouse"),
              width: "250",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.area.warehouse.name) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("WarehouseAreaRawMaterial.WarehouseArea"),
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.area.name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Warehouse.QTY"), width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.qty) +
                        " " +
                        _vm._s(_vm.rawMaterial.subUnit) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Warehouse.Actions"),
              fixed: "right",
              "min-width": "100px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--default",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: {
                          to:
                            "/warehouses/" +
                            scope.row.area.warehouse.uuid +
                            "/areas/" +
                            scope.row.area.uuid +
                            "/records?rawMaterialId=" +
                            _vm.rawMaterial.uuid,
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-time" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("WarehouseAreaRawMaterial.History"))
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }