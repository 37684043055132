"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _errorParse = require("@/utils/error-parse");

var _vueSelect = _interopRequireDefault(require("vue-select"));

var _rawMaterial = require("@/api/rawMaterial");

var _elementUi = require("element-ui");

var _supplier = require("@/api/supplier");

var _category = require("@/api/category");

var _warehouse = require("@/api/warehouse");

var _vuex = require("vuex");

var _lodash = _interopRequireDefault(require("lodash.debounce"));

require("vue-select/src/scss/vue-select.scss");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    vSelect: _vueSelect.default,
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      list: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        listAll: true,
        search: null,
        categoryId: null,
        rawMaterialWarehouseId: null,
        supplierId: null
      },
      supplierName: null,
      supplierSelectedName: null,
      categories: [],
      warehouses: [],
      total: 0
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    },

    ...(0, _vuex.mapGetters)(['user']),
    page: {
      get() {
        return this.listQuery.page;
      }

    },
    limit: {
      get() {
        return this.listQuery.limit;
      }

    }
  },
  watch: {
    page() {
      this.pushRouter();
    },

    limit() {
      this.pushRouter();
    }

  },

  async created() {
    (0, _category.getCategories)({
      perPage: 999
    }).then(response => {
      this.categories = response.data;
    });
    /* getSuppliers({ perPage: 999 }).then(response => {
      this.suppliers = response.data
    }) */

    (0, _warehouse.getWarehouses)({
      perPage: 999,
      limit: 999
    }).then(response => {
      this.warehouses = response.data;
    });

    for (const key in this.listQuery) {
      if (this.$route.query[key]) {
        if (typeof this.listQuery[key] === 'number') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else {
          this.listQuery[key] = this.$route.query[key];
        }
      }
    }

    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _rawMaterial.getRawMaterials)(this.listQuery).then(response => {
        this.list = response.data;
        this.list.forEach((data, index) => {
          data.id = index + response.meta.from;
        });
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    async fetchSupplier(queryString, cb) {
      (0, _lodash.default)(search => {
        (0, _supplier.getSuppliers)({
          perPage: 120,
          search
        }).then(response => cb(response.data.map(supplier => ({
          label: supplier.name,
          value: supplier.uuid
        }))));
      }, 630)(queryString);
    },

    clearSupplier() {
      this.listQuery.supplierId = null; // this.listQuery.suppliers = null

      this.supplierSelectedName = null;
    },

    checkSupplier() {
      if (this.supplierSelectedName !== this.supplierName) {
        this.supplierName = this.supplierSelectedName;
      }
    },

    handleSelectSupplier(item) {
      this.listQuery.supplierId = item.value; // this.listQuery.suppliers = item.value

      this.supplierSelectedName = item.label;
    },

    pushRouter() {
      return this.$router.push({
        query: Object.keys(this.listQuery).filter(key => ['rawMaterialWarehouseId', 'categoryId', 'supplierId', 'search', 'page', 'limit'].includes(key) && this.listQuery[key] !== null).reduce((obj, key) => {
          return { ...obj,
            [key]: this.listQuery[key]
          };
        }, {})
      });
    },

    query() {
      this.pushRouter();
      this.fetchData();
    },

    getRowClassName({
      row,
      rowIndex
    }) {
      if (row.active === 0) {
        // console.log(row.sku, row.active)
        return 'not-active';
      }
    },

    deleteRawMaterial(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: 'Hide',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _rawMaterial.deleteRawMaterial)(id).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Record deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    hideRawMaterial(id) {
      return _elementUi.MessageBox.confirm('Confirm to hide record?', 'Confirm', {
        confirmButtonText: 'Hide',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _rawMaterial.hideRawMaterial)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Record hided',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    activeRawMaterial(id) {
      return _elementUi.MessageBox.confirm('Confirm to active record?', 'Confirm', {
        confirmButtonText: 'Active',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _rawMaterial.activeRawMaterial)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Record reactived',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }

  }
};
exports.default = _default;